<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tarifarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administración</li>
                  <li class="breadcrumb-item active">Gestión Contratos</li>
                  <li class="breadcrumb-item active">Tarifarios</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h6 class="mb-0">Formulario {{ accion }} Tarifarios</h6>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-TarifasTarifarios"
                    data-toggle="tab"
                    href="#TarifasTarifarios"
                    v-if="form.id != null"
                    >Tarifas Tarifarios</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-TarifasKm"
                    data-toggle="tab"
                    href="#TarifasKm"
                    v-if="form.id != null"
                    >Tarifas Kilómetros</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-TarifariosTarifasAdicionales"
                    data-toggle="tab"
                    href="#TarifariosTarifasAdicionales"
                    v-if="form.id != null"
                    >Tarifas Adicionales</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="form-group col-md-1">
                            <div class="form-group">
                              <label for="nombre">Id</label>
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                v-model="form.id"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-6">
                            <div class="form-group">
                              <label for="nombre">Nombre</label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                :disabled="estado == 2"
                                v-model="form.nombre"
                                :class="
                                  $v.form.nombre.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="form-group col-md-2">
                            <label for="estado">Estado</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.estado"
                              :class="
                                $v.form.estado.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="estado == 2"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-md-3">
                            <label>Usuario</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              :value="responsable.nombre"
                              disabled
                            />
                            <input
                              v-model="form.user_id"
                              type="hidden"
                              class="form-control form-control-sm p-0"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="observaciones">Observaciones</label>
                            <br />
                            <textarea
                              vtype="date"
                              class="form-control"
                              id="observaciones"
                              v-model="form.observaciones"
                              rows="1"
                              :class="
                                $v.form.observaciones.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="estado == 2"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="TarifasTarifarios" v-if="form.id">
                  <div class="card-body">
                    <TarifasTarifarios ref="TarifasTarifarios"></TarifasTarifarios>
                  </div>
                </div>
                <div class="tab-pane fade" id="TarifasKm" v-if="form.id">
                  <div class="card-body">
                    <TarifasKm ref="TarifasKm"></TarifasKm>
                  </div>
                </div>
                <div class="tab-pane fade" id="TarifariosTarifasAdicionales" v-if="form.id">
                  <div class="card-body">
                    <TarifariosTarifasAdicionales ref="TarifariosTarifasAdicionales"></TarifariosTarifasAdicionales>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      $store.getters.can('tif.tarifarios.create') ||
                        $store.getters.can('tif.tarifarios.edit')
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
import TarifasTarifarios from "./TarifasTarifarios";
import TarifasKm from "./TarifasKm";
import TarifariosTarifasAdicionales from "./TarifariosTarifasAdicionales";


export default {
  name: "TarifariosForm",
  components: {
    Loading,
    TarifasTarifarios,
    TarifasKm,
    TarifariosTarifasAdicionales,
  },
  data() {
    return {
      cargando: false,
      act: 1,
      form: {
        nombre: null,
        observaciones: null,
        user_id: this.$store.getters.getUser.id,
        estado: 1,
      },
      responsable: {
        id: this.$store.getters.getUser.id,
        nombre: this.$store.getters.getUser.name,
      },
      accion: "",
      metodo: "",
      listasForms: {
        estados: [],
        empresas: [],
      },
      estado: null,
    };
  },
  validations() {
    return {
      form: {
        nombre: {
          required,
        },
        observaciones: {
          required,
        },
        user_id: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },
  methods: {
    init() {
      this.cargando = true;
      this.act = this.$route.params.act;
      this.accion = this.$route.params.accion;
      if (this.$route.params.data_edit) {
        this.estado = this.$route.params.data_edit.estado;
      }
      if (this.$route.params.accion == "Editar") {
        this.getForm();
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
      this.cargando = false;
    },

    getForm() {
      this.form = {
        id: this.$route.params.data_edit.id,
        nombre: this.$route.params.data_edit.nombre,
        observaciones: this.$route.params.data_edit.observaciones,
        user_id: this.$route.params.data_edit.user.id,
        estado: this.$route.params.data_edit.estado,
        tarifa_tarifario: this.$route.params.data_edit.tarifa_tarifario,
        tarifa_km: this.$route.params.data_edit.tarifa_km,
      };
    },

    save() {
      this.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/tif/tarifarios",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            this.form = response.data;
            this.estado = 2;
            this.$swal({
              icon: "success",
              title: "El tarifario se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    back() {
      return this.$router.replace("/Tif/Tarifarios");
    },
  },
  mounted() {
    this.init();
    this.getEstados();
  },
};
</script>
