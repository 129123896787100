<template>
  <div class="row">
    <div class="col-md-12 text-right mb-2"
      v-if="$store.getters.can('tif.tarifarios.create')"
    >
      <button
        data-target="#Modal_adicionales"
        data-toggle="modal"
        class="btn btn-primary"
        @click="getFormTarifa()"
      >
        <i class="fas fa-plus"></i>
      </button>
    </div>
    <div class="col-md-12">
      <table class="table table-bordered table-striped table-hover table-sm mb-0"
        v-if="$parent.$route.params.data_edit && $parent.$route.params.data_edit.tarifas_adicionales.length > 0"
      >
        <thead class="thead-dark">
          <tr>
            <th>Nombre</th>
            <th class="col-2">Vigencia</th>
            <th class="col-1">Valor</th>
            <th class="col-1">Estado</th>
            <th class="col-1">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tarifa in $parent.$route.params.data_edit.tarifas_adicionales" :key="tarifa.id">
            <td>{{ tarifa.nombre }}</td>
            <td class="text-center text-nowrap">{{ tarifa.detalles.length > 0 ? `${tarifa.detalles[0].fecha_ini} - ${tarifa.detalles[0].fecha_fin}` : "" }}</td>
            <td class="text-right">{{ tarifa.detalles.length > 0 ? tarifa.detalles[0].valor : "" }}</td>
            <td class="text-center">
              <span
                class="badge"
                v-bind:class="[
                  tarifa.estado == 1
                    ? 'badge-success'
                    : 'badge-danger',
                ]"
              >
                {{ tarifa.nEstado }}
              </span>
            </td>
            <td class="text-right">
              <div class="btn-group">
                <button
                  data-target="#Modal_adicionales"
                  data-toggle="modal"
                  class="btn btn-sm bg-navy"
                  v-if="
                    $store.getters.can('tif.tarifarios.edit') &&
                    ( $parent.estado_tarifario == 1 )
                  "
                  @click="getFormTarifa(tarifa)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  data-target="#Modal_adicionales_detalles"
                  data-toggle="modal"
                  class="btn btn-sm bg-info"
                  v-if="$store.getters.can('tif.tarifarios.edit')"
                  @click="getDataDet(tarifa)"
                >
                  <i class="fas fa-list"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="callout callout-warning text-muted m-0">
        <h5 class="m-0">No hay tarifas registradas!</h5>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="Modal_adicionales">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalAdicional"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <div class="row">
              <div class="form-group col-md-12">
                <label>Nombre</label>
                <input v-model="form_tarifa.nombre"
                  type="text"
                  class="form-control form-control-sm"
                  :class="$v.form_tarifa.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                  :disabled="
                    ( !$store.getters.can('tif.tarifarios.create') && !form_tarifa.id ) ||
                    ( !$store.getters.can('tif.tarifarios.edit') && form_tarifa.id )
                  "
                />
              </div>
              <div class="form-group col-md-4">
                <label>Estado</label>
                <select v-model="form_tarifa.estado"
                  class="form-control form-control-sm"
                  :class="$v.form_tarifa.estado.$invalid ? 'is-invalid' : 'is-valid'"
                  :disabled="
                    !form_tarifa.id ||
                    ( !$store.getters.can('tif.tarifarios.create') && !form_tarifa.id ) ||
                    ( !$store.getters.can('tif.tarifarios.edit') && form_tarifa.id )
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between"
            v-if="
              !$v.form_tarifa.$invalid &&
              ($store.getters.can('tif.tarifarios.create') ||
              $store.getters.can('tif.tarifarios.edit'))
            "
          >
            <button type="button" 
              class="btn btn-primary"
              @click="save()"
            >Guardar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="Modal_adicionales_detalles">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalDetalle"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-right mb-2">
                <button 
                  class="btn btn-primary"
                  @click="getFormStatusDet(1)"
                  :disabled="flag_form_detalle != 0"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
              <div class="col-md-12">
                <table class="table table-bordered table-striped table-hover table-sm mb-0"
                  v-if="det_tarifa.length > 0 || flag_form_detalle != 0"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th class="col-3">Fecha Inicial</th>
                      <th class="col-3">Fecha Final</th>
                      <th class="col-4">Valor</th>
                      <th class="col-1">Estado</th>
                      <th class="col-1">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="flag_form_detalle == 1">
                      <td class="text-center">
                        <input type="date"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_ini"
                          :class="$v.form_detalle.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'"
                          @input="validaFechas(null)"
                        />
                      </td>
                      <td class="text-center">
                        <input type="date"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_fin"
                          :class="$v.form_detalle.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'"
                          @input="validaFechas(null)"
                        />
                      </td>
                      <td class="text-right">
                        <input type="number"
                          class="form-control form-control-sm"
                          v-model="form_detalle.valor"
                          :class="$v.form_detalle.valor.$invalid ? 'is-invalid' : 'is-valid'"
                        />
                      </td>
                      <td class="text-center">
                        <span class="badge badge-success">ACTIVO</span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button type="button" 
                            class="btn btn-sm bg-success"
                            v-if="
                              !$v.form_detalle.$invalid &&
                              $store.getters.can('tif.tarifarios.edit')
                            "
                            @click="saveDetalle"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                          <button type="button"
                            class="btn btn-sm bg-danger"
                            @click="getFormStatusDet(0)"
                          >
                            <i class="fas fa-ban"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-for="(det, index) in det_tarifa" :key="index">
                      <td class="text-center">
                        <input type="date"
                          v-if="index === 0 && flag_form_detalle == 2"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_ini"
                          :class="$v.form_detalle.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'"
                          @input="(validaFechas(index))"
                        />
                        {{ index !== 0 || flag_form_detalle != 2 ? det.fecha_ini : "" }}
                      </td>
                      <td class="text-center">
                        <input type="date"
                          v-if="index === 0 && flag_form_detalle == 2"
                          class="form-control form-control-sm"
                          v-model="form_detalle.fecha_fin"
                          :class="$v.form_detalle.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'"
                          @input="validaFechas(index)"
                        />
                        {{ index !== 0 || flag_form_detalle != 2 ? det.fecha_fin : "" }}
                      </td>
                      <td class="text-right">{{ det.valor }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            det.estado == 1
                              ? 'badge-success'
                              : 'badge-danger',
                          ]"
                        >
                          {{ det.nEstado }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group" v-if="index === 0 && flag_form_detalle == 0">
                          <button type="button"
                            class="btn btn-sm bg-navy"
                            @click="getFormStatusDet(2, index)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                        <div class="btn-group" v-if="index === 0 && flag_form_detalle == 2">
                          <button type="button" 
                            class="btn btn-sm bg-success"
                            v-if="
                              !$v.form_detalle.$invalid &&
                              $store.getters.can('tif.tarifarios.edit')
                            "
                            @click="saveDetalle"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                          <button type="button"
                            class="btn btn-sm bg-danger"
                            @click="getFormStatusDet(0)"
                          >
                            <i class="fas fa-ban"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="callout callout-warning text-muted m-0">
                  <h5 class="m-0">No hay detalles de tarifa registrados!</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TarifariosTarifasAdicionales",

  components: {
  },

  data() {
    return {
      tif_tarifario_id: null,
      tif_tarifa_adicional_id: null,
      tarifa_estado: null,
      modal_title: "",
      listasForms: {
        equipos_types: [],
        tipo_tiempos_tarifas: [],
        clases_tarifas: [],
        estados: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
      },
      form_tarifa: {},
      flag_form_detalle: 0,
      det_tarifa: {},
      form_detalle: {},
    };
  },

  validations: {
    form_tarifa: {
      nombre: {
        required,
      },
      estado: {
        required,
      },
    },
    form_detalle: {
      valor: {
        required,
      },
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      estado: {
        required,
      },
    },
  },

  methods: {
    getIndex(){
      this.tif_tarifario_id = this.$parent.$route.params.id;
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    // Funciones para los formularios
    getFormTarifa(tarifa = null){
      this.modal_title = `Crear Tarifa`;
      this.resetFormTarifa();
      if (tarifa) {
        this.modal_title = `Editar Tarifa - ${tarifa.nombre}`;
        this.form_tarifa = {
          id: tarifa.id,
          tif_tarifario_id: tarifa.tif_tarifario_id,
          nombre: tarifa.nombre,
          estado: tarifa.estado,
        };
      }
    },

    resetFormTarifa(){
      this.form_tarifa = {
        id: null,
        tif_tarifario_id: this.tif_tarifario_id,
        nombre: null,
        estado: 1,
      };
    },

    getDataDet(tarifa){
      this.modal_title = `Histórico de Tarifa Adicional - ${tarifa.nombre}`;
      this.tif_tarifa_adicional_id = tarifa.id;
      this.tarifa_estado = tarifa.estado;
      this.flag_form_detalle = 0;
      this.det_tarifa = tarifa.detalles;
    },

    getFormStatusDet(status, index = null){
      this.flag_form_detalle = status;
      switch (status) {
        case 1:
          this.form_detalle = {
            id: null,
            tif_tarifa_adicional_id: this.tif_tarifa_adicional_id,
            valor: null,
            fecha_ini: null,
            fecha_fin: null,
            estado: 1,
          };
          break;
        case 2:
          this.form_detalle = {
            id: this.det_tarifa[index].id,
            tif_tarifa_adicional_id: this.det_tarifa[index].tif_tarifa_adicional_id,
            valor: this.det_tarifa[index].valor,
            fecha_ini: this.det_tarifa[index].fecha_ini,
            fecha_fin: this.det_tarifa[index].fecha_fin,
            estado: this.det_tarifa[index].estado,
          };
          break;
        default:
          break;
      }
    },

    // Funciones para validaciones de los datos 
    validaFechas(index) {
      let msg_error = "";
      if (this.form_detalle.fecha_ini) {
        const fecha_menor = new Date(this.form_detalle.fecha_ini);
        if (this.det_tarifa.length > 0) {
          let pos = index == null ? 0 : 1;
          if (this.det_tarifa[pos]) {
            const fecha_limite = new Date(this.det_tarifa[pos].fecha_fin);
            if (fecha_menor <= fecha_limite) {
              this.form_detalle.fecha_ini = null;
              msg_error = `La fecha inicio no puede ser menor a la fecha final de la tarifa actual...`;
            }
          }
        }
        if (this.form_detalle.fecha_ini && this.form_detalle.fecha_fin) {
          const fecha_mayor = new Date(this.form_detalle.fecha_fin);
          // Se valida que la fecha inicial sea menor que la fecha final
          if (fecha_menor > fecha_mayor) {
            this.form_detalle.fecha_fin = null;
            msg_error = `La fecha inicio no puede ser mayor a la fecha final...`;
          }
        }
      }

      if (msg_error) {
        this.$swal({
          icon: "error",
          title: msg_error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    // Funciones para las acciones de los formularios
    save() {
      if (!this.$v.form_tarifa.$invalid) {
        this.$parent.cargando = true;
        axios({
          method: "POST",
          url: "/api/tif/tarifarios/tarifas/adicionales",
          data: this.form_tarifa,
        })
          .then((response) => {
            this.$parent.cargando = false;
            if (!response.data.msg_error) {
              this.$refs.closeModalAdicional.click();
              this.$parent.$route.params.data_edit.tarifas_adicionales = response.data.tarifas;
              this.$swal({
                icon: "success",
                title: "El tarifario se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.form_tarifa.nombre = null;
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }

          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveDetalle() {
      if (!this.$v.form_detalle.$invalid) {
        this.$parent.cargando = true;
        this.form_detalle.tif_tarifario_id = this.tif_tarifario_id;
        axios({
          method: "POST",
          url: "/api/tif/tarifarios/tarifas/adicionales/detalle",
          data: this.form_detalle
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.$refs.closeModalDetalle.click();
            this.$parent.$route.params.data_edit.tarifas_adicionales = response.data.tarifas;
            this.$swal({
              icon: "success",
              title: "El detalle de la tarifa se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },

  mounted() {
    this.getEstados();
    this.getIndex();
  },
};
</script>
